import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueRouter from 'vue-router';

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  { path: '/', component: App },
]

const router = new VueRouter({
  routes // short for `routes: routes`
})

new Vue({
  render: h => h(App), router
}).$mount('#wrapper')
