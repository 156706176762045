<template>
  <div id="wrapper">
    <h1 v-if="language === 'de'"><span>Hier steht </span>Katholischer Klartext</h1>
    <h1 v-if="language === 'en'">Catholic Real Talk</h1>
    <div id="inner-wrapper">
      <div id="language" v-on:click="toggleLanguage()"><p v-if="language === 'de'"><b>DE</b> | EN</p><p v-if="language === 'en'">DE | <b>EN</b></p></div>

      <!-- div v-if="language === 'de'" class="h1">Machen Sie mit, entscheiden Sie selbst und stimmen Sie ab:</div>
      <div v-if="language === 'en'" class="h1">Join in, decide for yourself and vote:</div -->
      <!-- p v-if="language === 'de'" class="notice top-note">Sobald Sie zu einem Thema abgestimmt haben, fließt Ihre Bewertung sofort in das Gesamtergebnis ein. <br>
        Jeder kann nur einmal abstimmen, Sie können sich aber nachträglich korrigieren.<br>
        <strong>Wir setzen auf dieser Website keine Cookies ein.</strong></p>
      <p v-if="language === 'en'" class="notice top-note">As soon as you have voted on a topic, your evaluation immediately shapes the overall result. <br>
        Everyone can vote only once, but you can correct yourself afterwards.<br>
        <strong>We do not use cookies on this website.</strong></p>
      <p v-if="language === 'de'" class="notice top-note"><strong>Wenn Sie weiter informiert bleiben wollen, <a href="#contact-form-wrapper">können Sie uns gerne Ihre Kontaktdaten</a> hinterlassen.</strong></p>
      <p v-if="language === 'en'" class="notice top-note"><strong>In order to stay informed, <a href="#contact-form-wrapper">please leave us your contact details.</a></strong></p -->
      <div id="introduction" class="content-wrapper">
        <p v-if="language === 'de'" class="teaser">
          Bekanntlich gibt es unbestritten in der katholischen Kirche weltweit und vor Ort unschätzbar viel Gutes. 
          Dort bewirken viele wunderbare Menschen Lobenswertes, verkünden die Frohe Botschaft und praktizieren christliche Nächstenliebe;  
          daran soll weder gezweifelt noch ungerecht oder pauschal etwas kritisiert werden. <b class="no-break">A B E R:</b>
        </p>
        <p v-if="language === 'en'" class="teaser">
          There are indisputably many good things that can be found in the Catholic Church – both locally and worldwide. Many wonderful people do things worthy of praise, proclaim the Good News and practice Christian charity; this should neither be doubted nor unfairly or flatly criticized. <b class="no-break">B U T :</b>
        </p>
        <p v-if="language === 'de'" class="h2">
          Darum geht es jetzt:
        </p>
        <p v-if="language === 'en'" class="h2">
          It is about the following now:
        </p>
        <ul>
          <li>
            <p v-if="language === 'de'">Sie/wir sind als Gläubige/r der katholischen Kirche nach wie vor zwar unterschiedlich, 
              aber irgendwie verbunden. Sie leiden aufgrund der verkrusteten Strukturen und aktuellen 
              Zustände sowie wegen des Erscheinungsbildes an ihrer momentanen Verfasstheit. 
              Sie sind Mitglied oder bereits ausgetreten bzw. spielen mit diesem Gedanken. 
              Eigentlich fühlen Sie sich aber in Ihrer angestammten religiösen Heimat aufgehoben und wollen 
              sich nicht durch den Klerikalismus aus der Kirche vertreiben lassen.
            <strong>Da stehen Sie absolut nicht alleine da! Lassen Sie uns dies deutlich wahrnehmbar an gebotener 
              Stelle klar artikulieren und durch gemeinsame Stärke und unsere Vielzahl etwas bewirken! 
              Wir wollen dieses Sprachrohr initiieren – ein sichtbares Schaufenster des Unmutes!</strong>
            </p>
            <p v-if="language === 'en'">
              As believers of the Catholic Church, you / we are still different, but somehow connected. You are suffering due to the church’s encrusted structures and current conditions. You are either a member or have already officially resigned or are playing with this idea. But, as a matter of fact, you actually feel that you are in good hands within your traditional religious origins and do not want to be driven out of the church by clericalism.
              <strong>
                You are absolutely not alone! Let us articulate this clearly and perceptibly in due place and attain something by common strength and in large numbers! We want to initiate a channel for a common voice - a showcase of our displeasure!
              </strong>
            </p>
          </li>
          <li>
            <p v-if="language === 'de'">Es gibt durchaus veränderungswillige und der Zukunft zugewandten Bischöfe, 
              Priester und Ordensleute, die man bei deren Bestrebungen nach überfälligen 
              Reformen aktiv unterstützen sollte. 
            <strong>Sie sollen wissen, dass auch sie nicht alleine dastehen und eine große 
              Schar der Gläubigen auf sie vertraut und auf eine zeitnahe Erneuerung setzt. 
              Wir flankieren und spiegeln dies fortlaufend.</strong>
            </p>
            <p v-if="language === 'en'">
              There are by all means bishops, priests and religious brothers and sisters who are willing to change and face the future, and who should be actively supported in their efforts for overdue reforms.
              <strong>
                They should know that they are not alone and that a large number of the faithful trust in them and rely on a timely renewal. We flank and reflect this continuously.
              </strong>
            </p>
          </li>
          <li>
            <p v-if="language === 'de'">Aber es gibt leider auch Reformunfähige, Rückwärtsgewandte und Ewiggestrige, 
              die die gestaltbare Zukunft einer lebendigen und wirklichkeitsnahen Glaubensgemeinschaft 
              im angebrochenen 21. Jahrhundert aufs Spiel setzen. 
            <strong>Bieten wir Gläubige – hier in Zahlen messbar – diesen Verhinderern mit Zuversicht und Gottvertrauen gemeinsam die Stirn!
              <u>Wir fordern jetzt endlich einen zügigen Aufbruch in die Zukunft, ein ‘aggiornamento‘ – eine Anpassung der Kirche an die Wirklichkeit der Gegenwart! (Papst Joh. XXIII.)</u>
              </strong></p>
            <p v-if="language === 'en'">
              But unfortunately, there are also those who are incapable of reform, those who are backward-looking, and those who “die hard”, who put at risk the shapable future of a living and realistic community of faith in the 21st century.
              <strong>
                Let us believers - in measurable numbers - stand up together to these preventers with confidence and trust in God! <u>We finally demand a speedy departure into the future, an 'aggiornamento' - an adaptation of the church to the reality of the present! (Pope John XXIII)</u>
              </strong>
            </p>
          </li>
        </ul>
        <p v-if="language === 'de'" class="notice">
          Was wir <b>nicht</b> anstreben: keinen weiteren Verein mit Mitgliedsbeiträgen oder irgendwelchen bindenden Pflichten!
        </p>
        <p v-if="language === 'en'" class="notice">
          What we do <b>not</b> strive for: no further association with membership fees or any binding obligations! 
        </p>
        <h3 v-if="language === 'de'">Austritte aus der katholischen Kirche in Deutschland</h3>
        <h3 v-if="language === 'en'">Withdrawals from the Catholic Church in Germany</h3>
        <img src="../src/assets/kirchenaustritte.png" alt="Die Grafik zeigt die Entwicklung der Kirchenaustritte aus der katholischen Kirche in Deutschland von 1990 bis 2019" >
      </div>

      <!-- ############################### frage 1 ############################# -->
      <div class="content-wrapper" v-bind:class="[sectionsopen[1] ? 'open' : 'close']">
        <div class="content-head" v-on:click="toggleSection(1)">
          <div class="number">
            01
          </div>
          <h2 v-if="language === 'de'" class="topic-headline">
            <span class="positiv">
              + Demokratische Mitbestimmung
            </span>
            <span class="negativ">
              statt absolutistischer Monarchie –
            </span>
          </h2>
          <h2 v-if="language === 'en'" class="topic-headline">
            <span class="positiv">
              + Democratic co-determination
            </span>
            <span class="negativ">
              instead of absolutist monarchy -
            </span>
          </h2>
          <div class="opener-wrapper">
            <div class="opener">
            </div>
          </div>
        </div>
        <div class="content-body">
          <p v-if="language === 'de'" lang="de">
            Längst wird in allen freien, offenen und entwickelten Gesellschaften sowie großen Organisationen 
            ‘Macht auf Zeit’ verliehen. Gewaltenteilung, demokratische Legitimation, Mitbestimmung, Aufsicht 
            sowie regulatorische Kontrolle sind inzwischen errungene Selbstverständlichkeiten. 
            Vereine und Zusammenschlüsse sind nicht mehr denkbar ohne satzungsmäßigen alljährlichen Rechenschaftsbericht und 
            Entlastung der jeweils Verantwortlichen durch ihre zahlenden Mitglieder. Diese bei uns überall üblichen Mitwirkungsmöglichkeiten 
            gehören zwingend auch in eine Kirche, wenn sie ihre Zukunft nicht verspielen möchte. 
          </p>
          <p v-if="language === 'en'" lang="en">
            In all free, open and developed societies, as well as in large organisations, 'power for a time' has long been conferred. Separation of powers, democratic legitimation, co-determination, supervision as well as regulatory control have become as self-evident facts. Associations and unions are no longer conceivable without an annual report as per the articles of association and the remuneration of those responsible through the paying members. These opportunities for participation, which are common everywhere in our society, are also a must for the church if it does not want to gamble away its future. 
          </p>
        </div>
        <div id="poll1" class="poll">
          <h3 v-if="language === 'de'">Geregelte und gleichgewichtige Mitwirkung auf Augenhöhe auch der sogenannten Laien bei Entscheidungen in der katholischen Kirche ist mir ...</h3>
          <h3 v-if="language === 'en'">Regulated and equal participation at eye level including the so-called laity in decisions within the Catholic Church is  ...</h3>
          <ul id="chart1" class="chart" v-if="pollsvisible[1]">
            <li v-for="(percent1, index) in this.pollresults[1].results" :key="percent1 + index">
              <span v-bind:style="{ height: percent1 + '%'}" v-bind:title="percent1 > 0 ? percent1.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
          <vue-slider
              id="slider1"
              class="slider"
              v-model="pollvalues[1]"
              @change="changePoll(1)"
              :interval="1"
              :marks="true"
              :hide-label="true"
              :min="1"
              :max="7"
              width="86%"
          ></vue-slider>
          <div v-if="language === 'de'" class="units">
            <div class="irrelevant">unwichtig</div>
            <div class="indifferent">egal</div>
            <div class="important ">wichtig</div>
          </div>
          <div v-if="language === 'en'" class="units">
            <div class="irrelevant">Not important</div>
            <div class="indifferent">No opinion</div>
            <div class="important ">Important</div>
          </div>
        </div>
      </div>

      <!-- ############################### frage 2 ############################# -->
      <div class="content-wrapper" v-bind:class="[sectionsopen[2] ? 'open' : 'close']">
        <div class="content-head" v-on:click="toggleSection(2)">
          <div class="number">
            02
          </div>
          <h2 v-if="language === 'de'" class="topic-headline">
            <span class="positiv">
              + Aufarbeitung
            </span>
            <span class="negativ">
              statt Vertuschung –
            </span>
          </h2>
          <h2 v-if="language === 'en'" class="topic-headline">
            <span class="positiv">+ Unveiling the truth
            </span>
            <span class="negativ">instead of covering up -
            </span>
          </h2>
          <div class="opener-wrapper">
            <div class="opener">
            </div>
          </div>
        </div>
        <div class="content-body">
          <p v-if="language === 'de'">
            Fehlverhalten – sei es fahrlässig oder vorsätzlich – bis hin zu erwiesenen kriminellen Machenschaften 
            bedürfen einer wirkungsvollen Prävention, der Aufdeckung sowie der Konsequenzen bzw. Strafverfolgung. 
            Der vorgeschobene Schutz der Institution Kirche anstelle von Opferschutz darf nicht länger als Schein-Argument für 
            Heimlichtuerei und Vertuschung dienen.
          </p>
          <p v-if="language === 'en'">
            Misconduct - negligent or intentional - up to proven criminal intrigues require effective prevention, detection as well as consequences or prosecution. The pretended protection of the church as an institution instead of victim protection must no longer serve as a seeming argument for secrecy and cover-up. 
          </p>
        </div>
        <div id="poll2" class="poll">
          <h3 v-if="language === 'de'">Die konsequente Aufarbeitung, eine faire Opfer-Entschädigung und klare Präventions-Mechanismen bei Missbrauchsfällen ist mir ...</h3>
          <h3 v-if="language === 'en'">Rigorous uncovering, a fair victim compensation and clear prevention mechanisms in cases of abuse is ...</h3>
          <ul id="chart2" class="chart" v-if="pollsvisible[2]">
            <li v-for="(percent2, index) in this.pollresults[2].results" :key="percent2 + index">
              <span v-bind:style="{ height: percent2 + '%'}" v-bind:title="percent2 > 0 ? percent2.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
          <vue-slider
              id="slider2"
              class="slider"
              v-model="pollvalues[2]"
              @change="changePoll(2)"
              :interval="1"
              :marks="true"
              :hide-label="true"
              :min="1"
              :max="7"
              width="86%"
          ></vue-slider>
          <div v-if="language === 'de'" class="units">
            <div class="irrelevant">unwichtig</div>
            <div class="indifferent">egal</div>
            <div class="important ">wichtig</div>
          </div>
          <div v-if="language === 'en'" class="units">
            <div class="irrelevant">Not important</div>
            <div class="indifferent">No opinion</div>
            <div class="important ">Important</div>
          </div>
        </div>
      </div>

      <!-- ############################### frage 3 ############################# -->
      <div class="content-wrapper" v-bind:class="[sectionsopen[3] ? 'open' : 'close']">
        <div class="content-head" v-on:click="toggleSection(3)">
          <div class="number">
            03
          </div>
          <h2 v-if="language === 'de'" class="topic-headline">
            <span class="positiv">
              + wirklichkeitsnahe Lebensformen 
            </span>
            <span class="negativ">
              statt Pflicht-Zölibat –
            </span>
          </h2>
          <h2 v-if="language === 'en'" class="topic-headline">
            <span class="positiv">+ Realistic ways of life
            </span>
            <span class="negativ">instead of compulsory celibacy -
            </span>
          </h2>
          <div class="opener-wrapper">
            <div class="opener">
            </div>
          </div>
        </div>
        <div class="content-body">
          <p v-if="language === 'de'">
            Glaubwürdige Verantwortliche im Hirtenamt sollten wie alle Menschen das Recht und die Freiheit haben, 
            ihre Lebensform und ihren Familienstand selbst zu bestimmen. 
            Verheiratete sollten nicht vom Priesteramt ausgeschlossen werden. 
            Segensreiche Seelsorge und christliche 
            Zuwendung jedweder Form sowie Erziehung und Unterrichtung im Glauben erfordern Nähe zu den Menschen 
            in der Gemeinde und einen natürlichen Umgang miteinander auf Augenhöhe.
          </p>
          <p v-if="language === 'en'">
            Credible pastoral leaders, like all people, should have the right and freedom to determine their own lifestyle and marital status. Married people should not be excluded from the priesthood. Beneficial pastoral care and Christian sympathy in any form, as well as education and instruction in the faith, require closeness to the people in the congregation and natural interaction with one another on at eye-level. 
          </p>
        </div>
        <div id="poll3" class="poll">
          <h3 v-if="language === 'de'">Die Aufhebung des Pflicht-Zölibats und die freie Wahl der Lebensform für Geistliche ist mir ...</h3>
          <h3 v-if="language === 'en'">Abolition of compulsory celibacy the free choice of lifestyle for clergy is ...</h3>
          <ul id="chart3" class="chart" v-if="pollsvisible[3]">
            <li v-for="(percent3, index) in this.pollresults[3].results" :key="percent3 + index">
              <span v-bind:style="{ height: percent3 + '%'}" v-bind:title="percent3 > 0 ? percent3.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
          <vue-slider
              id="slider3"
              class="slider"
              v-model="pollvalues[3]"
              @change="changePoll(3)"
              :interval="1"
              :marks="true"
              :hide-label="true"
              :min="1"
              :max="7"
              width="86%"
          ></vue-slider>
          <div v-if="language === 'de'" class="units">
            <div class="irrelevant">unwichtig</div>
            <div class="indifferent">egal</div>
            <div class="important ">wichtig</div>
          </div>
          <div v-if="language === 'en'" class="units">
            <div class="irrelevant">Not important</div>
            <div class="indifferent">No opinion</div>
            <div class="important ">Important</div>
          </div>
        </div>
      </div>


      <!-- ############################### frage 4 ############################# -->
      <div class="content-wrapper" v-bind:class="[sectionsopen[4] ? 'open' : 'close']">
        <div class="content-head" v-on:click="toggleSection(4)">
          <div class="number">
            04
          </div>
          <h2 v-if="language === 'de'" class="topic-headline">
            <span class="positiv">
              + Gleichberechtigung
            </span>
            <span class="negativ">
              statt männlicher Dominanz –
            </span>
          </h2>
          <h2 v-if="language === 'en'" class="topic-headline">
            <span class="positiv">+ Equality 
            </span>
            <span class="negativ">instead of male dominance - 
            </span>
          </h2>
          <div class="opener-wrapper">
            <div class="opener">
            </div>
          </div>
        </div>
        <div class="content-body">
          <p v-if="language === 'de'">
            <b>Artikel 3 Abs. 2 Grundgesetz: „Männer und Frauen sind gleichberechtigt.“</b>
          </p><p v-if="language === 'de'">
            Die fortschreitende Gleichberechtigung sowie umfassende Teilnahme von Frauen zieht 
            sich immer stärker durch alle Lebensbereiche und Verantwortungsebenen unserer Gesellschaft.
          </p><p v-if="language === 'de'">
            <strong>Gott schuf den Menschen, Mann und Frau, als sein Ebenbild. Diese gleiche Würde bedingt gleiche Rechte.</strong>
          </p><p v-if="language === 'de'">
            Wer das weibliche Geschlecht per se und unter Berufung auf eine überkommene 
            Tradition von den wesentlichen Funktionen in der christlichen Seelsorge ausgrenzt und somit
            diskriminiert, verzichtet zudem auf die Fülle weiblicher Fähigkeiten, Qualitäten und Begabungen.
          </p>
          <p v-if="language === 'en'">
            <strong>Article 3 (2) of the German Constitution: "Men and women have equal rights."</strong>
          </p>
          <p v-if="language === 'en'">
            The progressing of equal rights as well as comprehensive participation of women increasingly permeates all areas of life and levels of responsibility in our society. 
          </p>
          <p v-if="language === 'en'">
            <strong>God created man in his own image, male and female he created them. This equal dignity implies equal rights.</strong>
          </p>
          <p v-if="language === 'en'">
            Those who exclude women per se and with reference to an outdated tradition from the essential functions in Christian pastoral care and thus discriminate against it, also forego the abundance of female abilities, qualities and talents. 
          </p>
        </div>
        <div id="poll4" class="poll">
          <h3 v-if="language === 'de'">Die Zulassung von Frauen zu allen Weiheämtern der Kirche ist mir ...</h3>
          <h3 v-if="language === 'en'">The admission of women to all ordained ministries of the Church is ...</h3>
          <ul id="chart4" class="chart" v-if="pollsvisible[4]">
            <li v-for="(percent4, index) in this.pollresults[4].results" :key="percent4 + index">
              <span v-bind:style="{ height: percent4 + '%'}" v-bind:title="percent4 > 0 ? percent4.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
          <vue-slider
              id="slider4"
              class="slider"
              v-model="pollvalues[4]"
              @change="changePoll(4)"
              :interval="1"
              :marks="true"
              :hide-label="true"
              :min="1"
              :max="7"
              width="86%"
          ></vue-slider>
          <div v-if="language === 'de'" class="units">
            <div class="irrelevant">unwichtig</div>
            <div class="indifferent">egal</div>
            <div class="important ">wichtig</div>
          </div>
          <div v-if="language === 'en'" class="units">
            <div class="irrelevant">Not important</div>
            <div class="indifferent">No opinion</div>
            <div class="important ">Important</div>
          </div>
        </div>
      </div>


      <!-- ############################### frage 5 ############################# -->
      <div class="content-wrapper" v-bind:class="[sectionsopen[5] ? 'open' : 'close']">
        <div class="content-head" v-on:click="toggleSection(5)">
          <div class="number">
            05
          </div>
          <h2 v-if="language === 'de'" class="topic-headline">
            <span class="positiv">
              + Transparenz
            </span>
            <span class="negativ">
              statt Geheimniskrämerei –
            </span>
          </h2>
          <h2 v-if="language === 'en'" class="topic-headline">
            <span class="positiv">+ Transparency 
            </span>
            <span class="negativ">instead of secrecy -
            </span>
          </h2>
          <div class="opener-wrapper">
            <div class="opener">
            </div>
          </div>
        </div>
        <div class="content-body">
          <p v-if="language === 'de'">
            Zeiten von Herrschaftswissen und Geheimnistuerei, intransparenten Finanzen und Schattenhaushalten, 
            undurchschaubaren Finanzströmen, Vermögenswerten sowie Quersubventionen etc. sollten endgültig 
            der Vergangenheit angehören. Ein treuhändisch aus Steuerzuflüssen der Gläubigen gespeister Kirchenhaushalt 
            sollte im Sinne vollständiger Transparenz korrekt, nachvollziehbar dokumentiert, einsehbar und 
            unabhängig kontrolliert sein. 
          </p><p v-if="language === 'de'">
            Besser noch würde die ideelle christliche Glaubensgemeinschaft, 
            der man durch Taufe und Bekenntnis als gläubiger Christ angehört, von einem diese Kirche unterstützenden 
            freiwilligen Förderverein mit separater Finanzierungsfunktion sauber getrennt.
          </p>
          <p v-if="language === 'en'">
            The times of dominance through knowledge and secrecy, non-transparent finances and shadow budgets, non-transparent financial flows, assets and cross-subsidies, etc. should finally be a thing of the past. A church budget fed with trust from tax inflows of the faithful should be correct, comprehensibly documented, accessible and independently controlled in the sense of complete transparency. 
          </p>
          <p v-if="language === 'en'">
            Better still, the idealistic Christian faith community, to which one belongs as a believing Christian through baptism and confession, should be cleanly separated from a voluntary support association with a separate financing function that supports this church. 
          </p>
        </div>
        <div id="poll5" class="poll">
          <h3 v-if="language === 'de'">Mehr Transparenz u.a. im Finanzgebaren inkl. Einnahmen und Ausgaben der Kirche ist mir ...</h3>
          <h3 v-if="language === 'en'">More transparency, among other things, regarding finances including income and expenditure of the church is ...</h3>
          <ul id="chart5" class="chart" v-if="pollsvisible[5]">
            <li v-for="(percent5, index) in this.pollresults[5].results" :key="percent5 + index">
              <span v-bind:style="{ height: percent5 + '%'}" v-bind:title="percent5 > 0 ? percent5.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
          <vue-slider
              id="slider5"
              class="slider"
              v-model="pollvalues[5]"
              @change="changePoll(5)"
              :interval="1"
              :marks="true"
              :hide-label="true"
              :min="1"
              :max="7"
              width="86%"
          ></vue-slider>
          <div v-if="language === 'de'" class="units">
            <div class="irrelevant">unwichtig</div>
            <div class="indifferent">egal</div>
            <div class="important ">wichtig</div>
          </div>
          <div v-if="language === 'en'" class="units">
            <div class="irrelevant">Not important</div>
            <div class="indifferent">No opinion</div>
            <div class="important ">Important</div>
          </div>
        </div>
      </div>

      <div class="content-wrapper simple">
        <div class="content-body">
          <h2 v-if="language === 'de'" class="enough">Uns reichts! <img src="../src/assets/es-reicht.png" alt=""><span>{{ allinterested }}</span></h2>
          <h2 v-if="language === 'en'" class="enough">We have had enough! <img src="../src/assets/es-reicht.png" alt=""><span>{{ allinterested }}</span></h2>
        </div>
      </div>

      <!-- ############################### frage 1 ja/nein ############################# -->
      <div class="content-wrapper simple">
        <div class="poll simple">    
          <p v-if="language === 'de'">Ich habe <strong>Vertrauen</strong> in die eigenständige Reformfähigkeit des amtierenden katholischen Klerus.</p>
          <p v-if="language === 'en'">I have <strong>confidence</strong> in the independent ability of the officiating Catholic clergy to initiate reforms.</p>
          <div v-if="language === 'de'" class="buttons">
            <label for="checkbox6Ja">
              <input type="radio" id="checkbox6Ja" value="Ja" v-model="pollvalues[6]" v-on:change="changePoll(6)">
              Ja
            </label>
            <label for="checkbox6Nein">
              <input type="radio" id="checkbox6Nein" value="Nein" v-model="pollvalues[6]" v-on:change="changePoll(6)">
              Nein
            </label>
          </div>
          <div v-if="language === 'en'" class="buttons">
            <label for="checkbox6Ja">
              <input type="radio" id="checkbox6Ja" value="Ja" v-model="pollvalues[6]" v-on:change="changePoll(6)">
              Yes
            </label>
            <label for="checkbox6Nein">
              <input type="radio" id="checkbox6Nein" value="Nein" v-model="pollvalues[6]" v-on:change="changePoll(6)">
              No
            </label>
          </div>
          <ul id="chart6" class="chart" v-if="pollsvisible[6]">
            <li v-for="(percent6, index) in this.pollresults[6].results" :key="percent6 + index">
              <span v-bind:style="{ height: percent6 + '%'}" v-bind:title="percent6 > 0 ? percent6.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
        </div>
      </div>

      <!-- ############################### frage 2 ja/nein ############################# -->
      <div class="content-wrapper simple">
        <div class="poll simple">    
          <p v-if="language === 'de'">Bisher fühle ich mich durch <strong>bloße Ankündigungen</strong>, denen keine Taten folgen, ewig vertröstet.</p>
          <p v-if="language === 'en'">So far, I have felt endlessly put off by <strong>mere announcements</strong> that are not followed by deeds.</p>
          <div v-if="language === 'de'" class="buttons">
            <label for="checkbox8Ja">
              <input type="radio" id="checkbox8Ja" value="Ja" v-model="pollvalues[8]" v-on:change="changePoll(8)">
              Ja
            </label>
            <label for="checkbox8Nein">
              <input type="radio" id="checkbox8Nein" value="Nein" v-model="pollvalues[8]" v-on:change="changePoll(8)">
              Nein
            </label>
          </div>
          <div v-if="language === 'en'" class="buttons">
            <label for="checkbox8Ja">
              <input type="radio" id="checkbox8Ja" value="Ja" v-model="pollvalues[8]" v-on:change="changePoll(8)">
              Yes
            </label>
            <label for="checkbox8Nein">
              <input type="radio" id="checkbox8Nein" value="Nein" v-model="pollvalues[8]" v-on:change="changePoll(8)">
              No
            </label>
          </div>
          <ul id="chart8" class="chart" v-if="pollsvisible[8]">
            <li v-for="(percent8, index) in this.pollresults[8].results" :key="percent8 + index">
              <span v-bind:style="{ height: percent8 + '%'}" v-bind:title="percent8 > 0 ? percent8.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
        </div>
      </div>

      <!-- ############################### frage 3 ja/nein + zahl steuern ############################# -->
      <div class="content-wrapper simple">
        <div class="poll simple">
          <p v-if="language === 'de'"><strong>‘Auszeit’ als effizientes finanzielles Druckmittel</strong> anstelle von endgültigem Austritt:</p>
          <p v-if="language === 'de'">Ich wäre bereit, temporär unter Berufung auf die vorgenannten Reform-Anstöße auszutreten, meine Kirchensteuer anderweitig zu spenden und nach wesentlichen Veränderungen wieder in meine Kirche einzutreten.</p>
          <p v-if="language === 'en'"><strong>'Time out' as an efficient financial leverage</strong> instead of definitive resignation:</p>
          <p v-if="language === 'en'">I would be willing to temporarily resign, citing the above-mentioned reform ideas, donate my church tax elsewhere, and rejoin my church after significant changes.</p>
          <div v-if="language === 'de'" class="buttons">
            <label for="checkbox7Ja">
              <input type="radio" id="checkbox7Ja" value="Ja" v-model="pollvalues[7]" v-on:change="changePoll(7)">
              Ja
            </label>
            <label for="checkbox7Nein">
              <input type="radio" id="checkbox7Nein" value="Nein" v-model="pollvalues[7]" v-on:change="changePoll(7)">
              Nein
            </label>
          </div>
          <div v-if="language === 'en'" class="buttons">
            <label for="checkbox7Ja">
              <input type="radio" id="checkbox7Ja" value="Ja" v-model="pollvalues[7]" v-on:change="changePoll(7)">
              Yes
            </label>
            <label for="checkbox7Nein">
              <input type="radio" id="checkbox7Nein" value="Nein" v-model="pollvalues[7]" v-on:change="changePoll(7)">
              No
            </label>
          </div>
          <div v-if="pollsvisible[7]">
            <div v-if="language === 'de'" class="kirchensteuer-hinweis">Es gehen der katholischen Kirche dadurch jährlich etwa <span>{{ taxlost }} €</span> an Kirchensteuer verloren.</div>
            <div v-if="language === 'en'" class="kirchensteuer-hinweis">The Catholic Church would lose about <span>{{ taxlost }} €</span> of church tax per year.</div>
            <ul id="chart7" class="chart">
              <li v-for="(percent7, index) in this.pollresults[7].results" :key="percent7 + index">
                <span v-bind:style="{ height: percent7 + '%'}" v-bind:title="percent7 > 0 ? percent7.toPrecision(3) + '%' : ''"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- ############################### frage 4 ja/nein ############################# -->
      <div class="content-wrapper simple">
        <div class="poll simple">    
          <p v-if="language === 'de'">Ich finde es im Sinne der Ökumene akzeptabel und mit meinem Gewissen vereinbar, 
            Einladungen der jeweils anderen Konfession (katholisch/protestantisch) zur Teilnahme an deren Eucharistie- bzw. 
            Abendmahls-Feier anzunehmen.</p>
          <p v-if="language === 'en'">I find it acceptable in the sense of the ecumenical Christianity and compatible with my conscience to accept invitations from the other religious denomination (Catholic/Protestant) to participate in their Eucharist or Communion celebrations.</p>
          <div v-if="language === 'de'" class="buttons">
            <label for="checkbox9Ja">
              <input type="radio" id="checkbox9Ja" value="Ja" v-model="pollvalues[9]" v-on:change="changePoll(9)">
              Ja
            </label>
            <label for="checkbox9Nein">
              <input type="radio" id="checkbox9Nein" value="Nein" v-model="pollvalues[9]" v-on:change="changePoll(9)">
              Nein
            </label>
          </div>
          <div v-if="language === 'en'" class="buttons">
            <label for="checkbox9Ja">
              <input type="radio" id="checkbox9Ja" value="Ja" v-model="pollvalues[9]" v-on:change="changePoll(9)">
              Yes
            </label>
            <label for="checkbox9Nein">
              <input type="radio" id="checkbox9Nein" value="Nein" v-model="pollvalues[9]" v-on:change="changePoll(9)">
              No
            </label>
          </div>
          <ul id="chart9" class="chart" v-if="pollsvisible[9]">
            <li v-for="(percent9, index) in this.pollresults[9].results" :key="percent9 + index">
              <span v-bind:style="{ height: percent9 + '%'}" v-bind:title="percent9 > 0 ? percent9.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
        </div>
      </div>


      <!-- ############################### frage 5 ja/nein ############################# -->
      <div class="content-wrapper simple">
        <div class="poll simple">    
          <p v-if="language === 'de'">Einen Brief u.a. an die Dt. Bischofskonferenz sowie an den Vatikan mit den vorgenannten Forderungen 1 – 5 unterschreibe ich symbolisch mit</p>
          <p v-if="language === 'en'">I symbolically sign a letter to the German Bishops' Conference and to the Vatican with the above-mentioned demands 1 - 5 with</p>
          <div v-if="language === 'de'" class="buttons">
            <label for="checkbox10Ja">
              <input type="radio" id="checkbox10Ja" value="Ja" v-model="pollvalues[10]" v-on:change="changePoll(10)">
              Ja
            </label>
            <label for="checkbox10Nein">
              <input type="radio" id="checkbox10Nein" value="Nein" v-model="pollvalues[10]" v-on:change="changePoll(10)">
              Nein
            </label>
          </div>
          <div v-if="language === 'en'" class="buttons">
            <label for="checkbox10Ja">
              <input type="radio" id="checkbox10Ja" value="Ja" v-model="pollvalues[10]" v-on:change="changePoll(10)">
              Yes
            </label>
            <label for="checkbox10Nein">
              <input type="radio" id="checkbox10Nein" value="Nein" v-model="pollvalues[10]" v-on:change="changePoll(10)">
              No
            </label>
          </div>
          <ul id="chart10" class="chart" v-if="pollsvisible[10]">
            <li v-for="(percent10, index) in this.pollresults[10].results" :key="percent10 + index">
              <span v-bind:style="{ height: percent10 + '%'}" v-bind:title="percent10 > 0 ? percent10.toPrecision(3) + '%' : ''"></span>
            </li>
          </ul>
          <!-- p v-if="pollsvisible[10]">
            <span v-if="language === 'de'"><strong style="color: rgb(0, 180, 0)">Vielen Dank, dass Sie an unserer Umfrage teilgenommen haben!</strong><br>
            Bei Interesse können Sie über unser Kontaktformular Ihre Kontaktdaten hinterlassen.</span>
            <span v-if="language === 'en'"><strong style="color: rgb(0, 180, 0)">Thank you for participating in our survey!</strong><br>
            If you are interested, you can leave your contact details via our contact form.</span>
          </p -->
        </div>
      </div>


      <!-- ############################### formular ############################# -->
      <!--
      <div v-if="language === 'de'" class="h1">Falls Sie Kontakt zu uns aufnehmen möchten:</div>
      <div v-if="language === 'en'" class="h1">If you would like to contact us:</div>
      <div class="content-wrapper simple" id="contact-form-wrapper">
        <div class="poll simple">
          <p v-if="language === 'de'"><strong style="color: rgb(229, 0, 0)">Freiwillig:</strong> Für weitere Ansprachen, Aktionen bzw. notwendige Kontaktaufnahmen hinterlasse ich mit Einverständnis meine <strong>Kontaktdaten</strong>.</p>
          <p v-if="language === 'en'"><strong style="color: rgb(229, 0, 0)">Voluntary:</strong> For further communication, actions or necessary contact, I leave my <strong>contact details </strong> with consent to being contacted.</p>
          <div v-if="language === 'de'" id="contact-form" class="contact-form">
            <div class="separator"></div>

            <div v-if="isSendingMail" class="loading">Formular wird gesendet ...</div>

            <form class="form" @submit="onContactFormSubmit" v-if="!MailSent">
              <input name="salutation" v-model='contact.salutation' placeholder="Anrede / Titel" type="text" autocomplete="off">
              <input required name="name" v-model='contact.name' placeholder="Name, Vorname" type="text" autocomplete="off">
              <input required name="email" v-model="contact.email" placeholder="E-Mail" type="email" autocomplete="on">
              <input required name="phone" v-model="contact.phone" placeholder="Telefonnummer" type="text" autocomplete="off">
              <button class="button">Kontaktdaten versenden</button>
            </form>
            <p class="notice">Namen, E-Mail, Telefon-Nr. – <b>vertraulich</b> und für Dritte nicht einsehbar.</p>
            <div class="mailmessage">{{ MailMessage }}</div>
            <p>Bitte weisen Sie möglichst viele Interessierte und evtl. Gleichgesinnte – ob in, schon oder noch außerhalb der kath. Kirche, 
              die einer lebendigen, christlichen, kirchlichen Glaubensgemeinschaft der Zukunft angehören wollen, 
              auf diese Initiative und einfache Abstimmungsmöglichkeit hin.</p>
            <p>Sprechen Sie die lokalen Kirchen-Verantwortlichen auf die o.a. Themen an und schreiben Sie Ihren zuständigen Bischöfen.</p>
          </div>
          <div v-if="language === 'en'" id="contact-form" class="contact-form">
            <div class="separator"></div>

            <div v-if="isSendingMail" class="loading">Form is being sent ...</div>

            <form class="form" @submit="onContactFormSubmit" v-if="!MailSent">
              <input name="salutation" v-model='contact.salutation' placeholder="Salutation" type="text" autocomplete="off">
              <input required name="name" v-model='contact.name' placeholder="Last Name, First Name" type="text" autocomplete="off">
              <input required name="email" v-model="contact.email" placeholder="E-Mail" type="email" autocomplete="on">
              <input required name="phone" v-model="contact.phone" placeholder="Phone number" type="text" autocomplete="off">
              <button class="button">Send contact details</button>
            </form>
            <p class="notice">Name, e-mail address, telephone number – <b>confidential</b> and not visible to third parties.</p>
            <div class="mailmessage">{{ MailMessage }}</div>
            <p>Please inform as many people as possible, who might be interested and potentially like-minded people, about this initiative and straight-forward voting possibility; whether they are within or outside of the Catholic Church but who want to belong to a lively, Christian, ecclesial faith community of the future.</p>
            <p>Approach local Church leaders about the above-mentioned issues and write to your relevant bishops.</p>
          </div>
        </div>
      </div>
      -->
      <div id="share-buttons">
        <p v-if="language === 'de'">Bitte teilen Sie diese Seite:</p>
        <p v-if="language === 'en'">Please share this page:</p>
        <!-- Sharingbutton Facebook -->
        <a class="resp-sharing-button__link" href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fkatholischer-klartext.de%2F" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
            </div>
          </div>
        </a>

        <!-- Sharingbutton Twitter -->
        <a class="resp-sharing-button__link" href="https://twitter.com/intent/tweet/?text=Wir%20reden%20katholischen%20Klartext!%20Eine%20private%20Initiative%20besorgter%20Christen.&amp;url=https%3A%2F%2Fkatholischer-klartext.de%2F" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg>
            </div>
          </div>
        </a>

        <!-- Sharingbutton E-Mail -->
        <a class="resp-sharing-button__link" href="mailto:?subject=Wir%20reden%20katholischen%20Klartext!%20Eine%20private%20Initiative%20besorgter%20Christen.&amp;body=https%3A%2F%2Fkatholischer-klartext.de%2F" target="_self" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--email resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z"/></svg>
            </div>
          </div>
        </a>

        <!-- Sharingbutton Pinterest -->
        <a class="resp-sharing-button__link" href="https://pinterest.com/pin/create/button/?url=https%3A%2F%2Fkatholischer-klartext.de%2F&amp;media=https%3A%2F%2Fkatholischer-klartext.de%2F&amp;description=Wir%20reden%20katholischen%20Klartext!%20Eine%20private%20Initiative%20besorgter%20Christen." target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--pinterest resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.14.5C5.86.5 2.7 5 2.7 8.75c0 2.27.86 4.3 2.7 5.05.3.12.57 0 .66-.33l.27-1.06c.1-.32.06-.44-.2-.73-.52-.62-.86-1.44-.86-2.6 0-3.33 2.5-6.32 6.5-6.32 3.55 0 5.5 2.17 5.5 5.07 0 3.8-1.7 7.02-4.2 7.02-1.37 0-2.4-1.14-2.07-2.54.4-1.68 1.16-3.48 1.16-4.7 0-1.07-.58-1.98-1.78-1.98-1.4 0-2.55 1.47-2.55 3.42 0 1.25.43 2.1.43 2.1l-1.7 7.2c-.5 2.13-.08 4.75-.04 5 .02.17.22.2.3.1.14-.18 1.82-2.26 2.4-4.33.16-.58.93-3.63.93-3.63.45.88 1.8 1.65 3.22 1.65 4.25 0 7.13-3.87 7.13-9.05C20.5 4.15 17.18.5 12.14.5z"/></svg>
            </div>
          </div>
        </a>

        <!-- Sharingbutton LinkedIn -->
        <a class="resp-sharing-button__link" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fkatholischer-klartext.de%2F&amp;title=Wir%20reden%20katholischen%20Klartext!%20Eine%20private%20Initiative%20besorgter%20Christen.&amp;summary=Wir%20reden%20katholischen%20Klartext!%20Eine%20private%20Initiative%20besorgter%20Christen.&amp;source=https%3A%2F%2Fkatholischer-klartext.de%2F" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z"/></svg>
            </div>
          </div>
        </a>

        <!-- Sharingbutton XING -->
        <a class="resp-sharing-button__link" href="https://www.xing.com/app/user?op=share;url=https%3A%2F%2Fkatholischer-klartext.de%2F;title=Wir%20reden%20katholischen%20Klartext!%20Eine%20private%20Initiative%20besorgter%20Christen." target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--xing resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.2 9.7l-3-5.4C7.2 4 7 4 6.8 4h-5c-.3 0-.4 0-.5.2v.5L4 10 .4 16v.5c0 .2.2.3.4.3h5c.3 0 .4 0 .5-.2l4-6.6v-.5zM24 .2l-.5-.2H18s-.2 0-.3.3l-8 14v.4l5.2 9c0 .2 0 .3.3.3h5.4s.3 0 .4-.2c.2-.2.2-.4 0-.5l-5-8.8L24 .7V.2z"/></svg>
            </div>
          </div>
        </a>

        <!-- Sharingbutton WhatsApp -->
        <a class="resp-sharing-button__link" href="whatsapp://send?text=Wir%20reden%20katholischen%20Klartext!%20Eine%20private%20Initiative%20besorgter%20Christen.%20https%3A%2F%2Fkatholischer-klartext.de%2F" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z"/></svg>
            </div>
          </div>
        </a>

        <!-- Sharingbutton Telegram -->
        <a class="resp-sharing-button__link" href="https://telegram.me/share/url?text=Wir%20reden%20katholischen%20Klartext!%20Eine%20private%20Initiative%20besorgter%20Christen.&amp;url=https%3A%2F%2Fkatholischer-klartext.de%2F" target="_blank" rel="noopener" aria-label="">
          <div class="resp-sharing-button resp-sharing-button--telegram resp-sharing-button--small"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z"/></svg>
            </div>
          </div>
        </a>
      </div>

      <!-- div id="hash">Hash: {{ hash }}</div-->
      <footer v-if="language === 'de'">
        <a href="index.html">Klartext</a> | 
        <a href="Presseecho.html">Echo (Presse, Medien etc.)</a> | 
        <a href="Links.html">Links</a> | 
        <a href="Impressum.html">Impressum</a> | 
        <a href="Datenschutz.html">Datenschutz</a>
      </footer>
      <footer v-if="language === 'en'">
        <a href="index.html">Real Talk</a> | 
        <a href="Presseecho.html">Echo (Press, Media etc.) [DE]</a> | 
        <a href="Links.html">Links [DE]</a> | 
        <a href="Impressum.html">Imprint [DE]</a> | 
        <a href="Datenschutz.html">Privacy Policy [DE]</a>
      </footer>
    </div>

  </div>

</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
//import 'js-captcha'

export default {
  name: 'App',
  components: {
    VueSlider,
  },
  data() {
    return {
      hash: '',
      iaminterested: localStorage.getItem('iami'),
      allinterested: 0,
      language: 'de',
      sectionsopen: [false,true,false,false,false,false],
      pollsvisible: [false,false,false,false,false,false,false,false,false,false,false,false],
      pollvalues: { // Aktuelle, persönliche Einstellung Umfragen
          "1" : localStorage.getItem('poll1') ? localStorage.getItem('poll1') : 4,
          "2" : localStorage.getItem('poll2') ? localStorage.getItem('poll2') : 4,
          "3" : localStorage.getItem('poll3') ? localStorage.getItem('poll3') : 4,
          "4" : localStorage.getItem('poll4') ? localStorage.getItem('poll4') : 4,
          "5" : localStorage.getItem('poll5') ? localStorage.getItem('poll2') : 4,
          "6" : localStorage.getItem('poll6'),
          "7" : localStorage.getItem('poll7'),
          "8" : localStorage.getItem('poll8'),
          "9" : localStorage.getItem('poll9'),
          "10" : localStorage.getItem('poll10'),
      },
      pollresults: { // % Ergebnisse Umfragen aus DB
        "1": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "2": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "3": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "4": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "5": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "6": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "7": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "8": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "9": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
        "10": {
          "results": [], // %
          "votes": 0 // Anzahl der Stimmen
        },
      },
      polltimers: [],
      taxlost: 0,
      taxlostfactor: 300, // € per lost payer
      contact: {
        name: '',
        email: '',
        phone: '',
        captcha: '',
      },
      isSendingMail: false,
      MailMessage: '',
      MailSent: false
    }
  },
  mounted: function () {
    console.log(localStorage.getItem('hashsesh'))
    if (!localStorage.getItem('hashsesh')) {
      localStorage.setItem('hashsesh', '_' + Math.random().toString(36).substr(2, 9))
    }
    localStorage.getItem('lang')
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'de')
    } else {
      this.language = localStorage.getItem('lang')
    }
    this.hash = localStorage.getItem('hashsesh');
    this.getAllInterested()

    console.log("route", this.$route);
    /*
    if (this.$route.query.results == "1") {
      this.showAllPollResults()
    }
    */
    this.showAllPollResults()
  },
  methods: {
    toggleSection: function(nr){
      this.$set(this.sectionsopen, nr, !this.sectionsopen[nr])
      //console.log(this.sectionsopen[nr]);
    },
    toggleLanguage: function() {
      if(this.language === 'de') {
        this.language = 'en'
      } else {
        this.language = 'de'
      }
      
      localStorage.setItem('lang', this.language)
    },
    getAllInterested: function(){
      this.$http
        .get('api/count.js')
        .then(response => {
          console.log(response.data);
          this.allinterested = response.data[0].Count1;
        })
        .catch(error => {
          console.log(error);
        });
    },
    setInterested: function(){
      console.log("setInterested")
      if (!this.iaminterested) {
        this.$http
            .post('api/count.php',1)
            .then(response => {
              this.iaminterested = true
              this.allinterested = response.data[0].Count1;
              localStorage.setItem('iami', true)
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            });
      }
    },
    showAllPollResults: function(){
      this.getPollResult(1);
      this.getPollResult(2);
      this.getPollResult(3);
      this.getPollResult(4);
      this.getPollResult(5);
      this.getPollResult(6);
      this.getPollResult(7);
      this.getPollResult(8);
      this.getPollResult(9);
      this.getPollResult(10);
      this.sectionsopen = [true,true,true,true,true,true];
      this.pollsvisible = [true,true,true,true,true,true,true,true,true,true,true,true];
    },
    getPollResult: function(nPollNr){
      console.log(nPollNr);
      this.$http
          .get('api/poll.php?nr=' + nPollNr)
          .then(response => {
            console.log(response.data);
            this.pollresults[nPollNr].votes = response.data[0].votes;
            if (typeof response.data[0].votefor3 !== 'undefined') {
              this.pollresults[nPollNr].results = [response.data[0].votefor1 / response.data[0].votes * 100, response.data[0].votefor2 / response.data[0].votes * 100, response.data[0].votefor3 / response.data[0].votes * 100, response.data[0].votefor4 / response.data[0].votes * 100, response.data[0].votefor5 / response.data[0].votes * 100, response.data[0].votefor6 / response.data[0].votes * 100, response.data[0].votefor7 / response.data[0].votes * 100]
            }
            else {
              this.pollresults[nPollNr].results = [response.data[0].votefor1 / response.data[0].votes * 100, response.data[0].votefor2 / response.data[0].votes * 100]
            }

            if (nPollNr == 7) {
              this.taxlost = response.data[0].votefor1 * this.taxlostfactor;
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    changePoll: function(nr){
      console.log("Poll!? " + this.pollvalues[nr])

      // clearTimeout(this.polltimers[nr]);
      // this.polltimers[nr] = setTimeout(function () { this.sendPoll(nr, this.pollvalues[nr]) }.bind(this), 1500)
    },
    sendPoll: function(nr, val){
      console.log("SEND Poll!", nr, val)
      localStorage.setItem('poll' + nr, val);
      this.setInterested();
      const formData = new FormData();
      formData.append('hash', this.hash)
      formData.append('nr', nr)
      formData.append('val', val)

      this.$http
          .post('api/poll.php', formData)
          .then(response => {
            console.log(response);
            this.getPollResult(nr);
            this.pollsvisible[nr] = true
            this.$set(this.pollsvisible, nr, true)
          })
          .catch(error => {
            console.log(error)
          });
    },
    clearContactForm() {
      for (let field in this.contact) {
        this.contact[field] = ''
      }
    },
    onContactFormSubmit(evt) {
      evt.preventDefault();
      console.log(evt, this.contact.captcha);

      //myCaptcha.validate();

      this.isSendingMail = true;

      setTimeout(() => {
        let form = new FormData();
        for (let field in this.contact) {
          form.append(field, this.contact[field]);
        }

        // Send form to server
        this.$http.post('api/mail.php', form).then((response) => {
          console.log(response);
          this.setMailMessage(response.data);
          this.clearContactForm();
          this.isSendingMail = false;
        }).catch((e) => {
          console.log(e)
        });
      }, 1000);
    },
    setMailMessage(message) {
      console.log("setMailMessage" + message);
      this.MailSent = message == "ok"
      this.MailMessage = message
    }
  }
}
</script>

<style lang="scss">
  @import './style/main.scss';
</style>
